<template>
  <div class="Donation">
    <div>
      <div
        id="MindSupport"
        class="widget"
        value="donation-form+widget"
        data-slug="MindSupport"
        data-lang="en"
        data-card="show"
        data-success_url=""
        data-fail_url=""
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://res.cloudinary.com/dxhaja5tz/raw/upload/script_main.js"
    );
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
.Donation {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>